import React, { Component } from "react"
import PropTypes from "prop-types"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

class CaseStudyItem extends React.Component {
  static propTypes = {
    caseStudy: PropTypes.object.isRequired,
  }

  render() {
    const {
      props: { caseStudy },
    } = this

    return (
      <div className="nd-case-study-item">
        <div className="ft-image">
          <h3>{caseStudy.title}</h3>
          <img src={caseStudy.imgUrl} alt="" />
        </div>

        <div className="info-lst">
          {caseStudy.infoLst.map((info, index) => (
            <div key={index} className="info-item">
              {/* <h4>{info.heading}</h4> */}
              <CountUp
                start={0}
                end={info.countNum}
                duration={2}
                prefix={info.prefix}
                suffix={info.suffix}
                separator=","
                decimals={info.decimals}
              >
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <h4 ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
              <p>{info.subHeading}</p>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default CaseStudyItem
