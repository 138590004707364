import React from "react"
import { Link } from "gatsby"
import LogoMarkImg from "../../images/landing-pages/logo-mark.svg"

class YourOrganicSection extends React.Component {
  render() {
    const handleClickScroll = () => {
      const element = document.getElementById("nd-form-ss")
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: "smooth" })
      }
    }
    return (
      <section className="your-organic-ss">
        <div className="ld-container">
          <div className="your-organic-wrap">
            <div className="logo">
              <img src={LogoMarkImg} alt="" />
            </div>
            <h3>We got you covered for all your organic search needs:</h3>

            <div className="organic-lst">
              <ul>
                <li>SEO Strategy + SEO Audits</li>
                <li>Site migrations</li>
                <li>Content creation</li>
                <li>On-page optimisation</li>
                <li>Off-page optimisation (link-building)</li>
                <li>Local SEO</li>
                <li>International SEO</li>
                <li>Voice SEO</li>
                <li>App store optimisation</li>
                <li>Social platform optimisation (eg: YouTube)</li>
              </ul>
            </div>

            <div className="cta-block">
              <h4>
                Get Measurably Better™ performance from your organic search
                strategy
              </h4>
              <a className="btn" onClick={handleClickScroll}>
                LET’S TALK
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default YourOrganicSection
