import React from "react"
import SearchEngineForm from "./blocks/search-engine-form"
import SearchIcon from "../../images/landing-pages/icons/search.png"
import AnalystIcon from "../../images/landing-pages/icons/analyst.png"
import Analyst2Icon from "../../images/landing-pages/icons/analyst2.png"
import RoadMapIcon from "../../images/landing-pages/icons/roadmap.png"

class SearchPresenceSection extends React.Component {
  render() {
    return (
      <section className="search-presence-ss">
        <div className="ld-container">
          <div className="search-presence-wrap">
            <h2>Benchmark your current organic search presence</h2>

            <div className="sp-content">
              <div className="left-col">
                <div className="presence-lst">
                  <div>
                    <div className="icon">
                      <img src={SearchIcon} alt="" />
                    </div>
                    <div className="text">
                      <h4>Search trends</h4>
                      <p>
                        Understand how your audiences are engaging with search
                        and what trends are around the corner
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="icon">
                      <img src={AnalystIcon} alt="" />
                    </div>
                    <div className="text">
                      <h4>Market analysis</h4>
                      <p>
                        Assess how your brand is performing in regards to
                        organic share of voice and quantify the value of
                        improved performance
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="icon">
                      <img src={Analyst2Icon} alt="" />
                    </div>
                    <div className="text">
                      <h4>Competitor analysis</h4>
                      <p>
                        Get insight into how your competitors are performing in
                        organic search
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="icon">
                      <img src={RoadMapIcon} alt="" />
                    </div>
                    <div className="text">
                      <h4>Build roadmap</h4>
                      <p>
                        Define both an immediate and long-term roadmap to
                        capture more organic search traffic
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right-col">
                <SearchEngineForm submitBtnText="GET YOUR AUDIT" />
              </div>
            </div>

            <div className="sp-description">
              <h3>Who’s a fit for Next&Co?</h3>
              <p>
                We work with <b>established, customer-centric brands</b> that
                want to challenge the status quo and put <b>performance</b> at
                the heart of what they do.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SearchPresenceSection
