import React, { Component } from "react"
import PropTypes from "prop-types"

class AccordionItem extends React.Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    this.props.onClick(this.props.label)
  }

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this

    return (
      <div className={`acc-item  ${isOpen && "is-open"}`}>
        <div onClick={onClick} className="acc-heading">
          {label}
        </div>
        <div className="acc-content">{this.props.children}</div>
      </div>
    )
  }
}

export default AccordionItem
