import React, { Component } from "react"
import PropTypes from "prop-types"

class TextImage extends React.Component {
  static propTypes = {
    imgUrl: PropTypes.string.isRequired,
    heading: PropTypes.string,
    children: PropTypes.instanceOf(Object).isRequired,
  }

  static defaultProps = {
    heading: "",
  }

  render() {
    const {
      props: { imgUrl, heading, children },
    } = this

    return (
      <>
        {heading && <h2 className="heading">{heading}</h2>}
        <div className="nd-text-image-wrap">
          <div className="left-col">
            <img src={imgUrl} alt="" />
          </div>

          <div className="right-col">{children}</div>
        </div>
      </>
    )
  }
}

export default TextImage
