import React from "react"
import SearchEngineForm from "./blocks/search-engine-form"

class HeroGreenSection extends React.Component {
  render() {
    return (
      <section className="hero-green-ss">
        <div className="ld-container">
          <div className="hero-wrap">
            <div className="left-col">
              <h1>
                Get Measurably Better™ performance from your organic search
                strategy.{" "}
              </h1>
              <p className="desc">
                Talk to us about a performance-based SEO strategy.
              </p>
            </div>

            <div className="right-col">
              <SearchEngineForm submitBtnText="LET’S TALK" />
            </div>
          </div>
          <div className="mobile-desc">
            <p>Talk to us about a performance-based SEO strategy.</p>
          </div>
        </div>
      </section>
    )
  }
}

export default HeroGreenSection
