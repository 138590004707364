import React from "react"
import WhyNextCoImg from "../../images/landing-pages/why-nextco-ss-img.png"
import TextImage from "../../components/landing-pages/blocks/text-image.js"

class WhyNextCoGreenSection extends React.Component {
  render() {
    return (
      <section className="why-nextco-ss">
        <div className="ld-container">
          <TextImage imgUrl={WhyNextCoImg}>
            <h2>Why Next&Co?</h2>
            <p>
              <b>A focus on the commercials.</b> We focus on deploying the
              specific elements of organic search optimisation strategy that
              will get you a search presence that{" "}
              <b>delivers your brand commercial outcomes and ROI.</b>
            </p>
            <p>
              Deal with the do’ers. We don’t have account managers at Next&Co so
              you would be{" "}
              <b>speaking directly experts working on your account.</b>
            </p>
            <p>
              <b>Performance based.</b> We implement performance-based pricing
              to align your brands expectations and our performance.
            </p>
          </TextImage>
        </div>
      </section>
    )
  }
}

export default WhyNextCoGreenSection
