import React from "react"
import BrandImg from "../../images/landing-pages/brand-ss-img.png"
import TextImage from "../../components/landing-pages/blocks/text-image.js"

class BrandSection extends React.Component {
  render() {
    return (
      <section className="brand-ss">
        <div className="ld-container">
          <TextImage imgUrl={BrandImg}>
            <p>
              Our entire team of content producers, strategists & developers
              look at your brands organic search strategy in a{" "}
              <b>holistic, omnichannel manner.</b> We look to grow your organic
              search footprint to turn your <b>domain into an authority</b>{" "}
              within your category, thereby capturing an increased share of
              voice with the content your brand produces.
            </p>
            <p>
              We look to leverage assets from your <b>entire brand ecosystem</b>{" "}
              for the benefit of your organic search program.
            </p>
          </TextImage>
        </div>
      </section>
    )
  }
}

export default BrandSection
