import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

class CtaItem extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    ctaLink: PropTypes.string.isRequired,
    ctaText: PropTypes.string.isRequired,
  }

  render() {
    const {
      props: { heading, ctaLink, ctaText },
    } = this

    const handleClickScroll = () => {
      const element = document.getElementById("nd-form-ss")
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: "smooth" })
      }
    }

    return (
      <section className="cta-section">
        <div className="ld-container">
          <div className="nd-cta-wrap">
            <div className="left-col">
              <h3>{heading}</h3>
            </div>

            <div className="right-col">
              <a className="btn" onClick={handleClickScroll}>
                {ctaText}
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default CtaItem
