import React from "react"
import { Link } from "gatsby"
import LogoImg from "../../../images/landing-pages/logo.svg"
import AnnouncementBar from "../../announcement-bar"

class NavbarLanding extends React.Component {
  render() {
    return (
      <>
        <AnnouncementBar />
        <header className="landing-header">
          <div className="ld-container">
            <div className="header-wrap">
              <div className="left-col">
                <Link to="/" className="site-logo" title="Logo">
                  <img src={LogoImg} alt="Next&Co" />
                </Link>
              </div>

              <div className="right-col">
                <a className="phone-number" href="tel:1300191950">
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0836 12.3043C13.0116 14.2371 14.2767 16.0486 15.8788 17.6507C17.4809 19.2528 19.2924 20.5179 21.2252 21.4459C21.3915 21.5257 21.4746 21.5656 21.5798 21.5963C21.9536 21.7053 22.4126 21.627 22.7292 21.4003C22.8183 21.3365 22.8945 21.2603 23.0469 21.1079C23.513 20.6417 23.7461 20.4087 23.9805 20.2563C24.8643 19.6816 26.0038 19.6816 26.8876 20.2563C27.122 20.4087 27.3551 20.6417 27.8212 21.1079L28.081 21.3677C28.7896 22.0763 29.1439 22.4306 29.3364 22.8111C29.7191 23.5679 29.7191 24.4615 29.3364 25.2183C29.1439 25.5988 28.7896 25.9531 28.081 26.6617L27.8708 26.8719C27.1647 27.578 26.8116 27.9311 26.3316 28.2008C25.7989 28.5 24.9716 28.7152 24.3606 28.7133C23.81 28.7117 23.4337 28.6049 22.6811 28.3913C18.6366 27.2433 14.8201 25.0774 11.6361 21.8934C8.45215 18.7094 6.28619 14.8929 5.13823 10.8484C4.92462 10.0958 4.81781 9.7195 4.81617 9.16891C4.81436 8.55795 5.0295 7.73062 5.32873 7.19795C5.5984 6.71791 5.95148 6.36482 6.65764 5.65866L6.86782 5.44848C7.57641 4.73989 7.93071 4.3856 8.31121 4.19314C9.06796 3.81038 9.96165 3.81038 10.7184 4.19314C11.0989 4.3856 11.4532 4.73989 12.1618 5.44848L12.4216 5.70831C12.8878 6.17445 13.1208 6.40752 13.2732 6.64189C13.8479 7.52574 13.8479 8.66518 13.2732 9.54904C13.1208 9.78341 12.8878 10.0165 12.4216 10.4826C12.2692 10.635 12.193 10.7112 12.1292 10.8003C11.9025 11.1169 11.8243 11.5759 11.9332 11.9497C11.9639 12.0549 12.0038 12.138 12.0836 12.3043Z"
                      stroke="#21ABC3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>1300 191 950</span>
                </a>
              </div>
            </div>
          </div>
        </header>
      </>
    )
  }
}

export default NavbarLanding
