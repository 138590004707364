import React from "react"
import PropTypes from "prop-types"
import Accordion from "../accordion/accordion"

class FAQSection extends React.Component {
  static propTypes = {
    faqs: PropTypes.array.isRequired,
  }

  render() {
    const {
      props: { faqs },
    } = this

    return (
      <section className="faq-ss">
        <div className="ld-container">
          <div className="faq-wrap">
            <div className="heading">
              <h2>Frequently asked questions</h2>
            </div>

            <div className="faq-lst">
              <Accordion allowMultipleOpen>
                {faqs &&
                  faqs.map((faq, index) => (
                    <div
                      key={index}
                      label={faq.question}
                      isOpen={index == 0 ? true : false}
                    >
                      <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                    </div>
                  ))}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default FAQSection
