import React from "react"
import CaseStudyItem from "../../components/landing-pages/blocks/case-study-item.js"
import CaseStudyImg1 from "../../images/landing-pages/case-study-green-1.jpeg"
import CaseStudyImg2 from "../../images/landing-pages/case-study-green-2.jpeg"
import CaseStudyImg3 from "../../images/landing-pages/case-study-green-3.jpeg"
import CaseStudyImg4 from "../../images/landing-pages/case-study-green-4.jpeg"

class CaseStudyGreenSection extends React.Component {
  render() {
    const caseStudyLst = [
      {
        imgUrl: CaseStudyImg1,
        title: "Drive.com.au",
        infoLst: [
          {
            countNum: 2551,
            prefix: "+",
            suffix: "",
            subHeading: "keywords into top 3 rankings for overall site",
          },
          {
            countNum: 298,
            prefix: "",
            suffix: "%",
            subHeading: "increase in traffic for EV content",
          },
          {
            countNum: 526,
            prefix: "+",
            suffix: "k",
            subHeading: "organic search visits QoQ",
          },
        ],
      },
      {
        imgUrl: CaseStudyImg2,
        title: "Get Wines Direct",
        infoLst: [
          {
            countNum: 45,
            prefix: "",
            suffix: "%",
            subHeading: "increase in revenue",
          },
          {
            countNum: 278,
            prefix: "",
            suffix: "%",
            subHeading: "increase in organic traffic",
          },
          {
            countNum: 11,
            prefix: "",
            suffix: "%",
            subHeading: "increase in avg. order value",
          },
          {
            countNum: 43,
            prefix: "",
            suffix: "%",
            subHeading: "improvement in spend efficiency",
          },
        ],
      },
      {
        imgUrl: CaseStudyImg3,
        title: "Dropshipzone",
        infoLst: [
          {
            countNum: 109,
            prefix: "",
            suffix: "%",
            subHeading: "increase in overall keywords ranking",
          },
          {
            countNum: 68,
            prefix: "",
            suffix: "%",
            subHeading: "increase in organic web traffic",
          },
          {
            countNum: 1534,
            prefix: "+",
            suffix: "",
            subHeading: "keywords ranking",
          },
          {
            countNum: 5.7,
            decimals: 1,
            prefix: "",
            suffix: "k",
            subHeading: "users visiting new content via non-branded keywords",
          },
        ],
      },
      {
        imgUrl: CaseStudyImg4,
        title: "Brand House Direct",
        infoLst: [
          {
            countNum: 91.2,
            decimals: 1,
            prefix: "",
            suffix: "%",
            subHeading: "increase in overall keywords",
          },
          {
            countNum: 48.9,
            decimals: 1,
            prefix: "",
            suffix: "%",
            subHeading: "increase in organic web traffic",
          },
          {
            countNum: 115.4,
            decimals: 1,
            prefix: "",
            suffix: "%",
            subHeading: "increase in organic revenue",
          },
          {
            countNum: 138,
            prefix: "",
            suffix: "%",
            subHeading: "increase in organic purchases",
          },
        ],
      },
    ]

    return (
      <section className="case-study-ss">
        <div className="ld-container">
          <div className="case-study-wrap">
            <h3 className="heading">
              We specialize in getting brands a dominant share of voice in
              organic search for keyword themes that deliver commercial results.
            </h3>

            <div className="case-study-lst">
              {caseStudyLst.map((caseStudy, index) => (
                <CaseStudyItem key={index} caseStudy={caseStudy} />
              ))}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default CaseStudyGreenSection
