import React from "react"
import PropTypes from "prop-types"
import LogoMarkImg from "../../images/landing-pages/logo-mark.svg"
import SearchEngineForm from "./blocks/search-engine-form"

class FormSection extends React.Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
  }

  render() {
    const {
      props: { heading },
    } = this

    return (
      <section id="nd-form-ss" className="nd-form-ss">
        <div className="ld-container">
          <div className="nd-form-wrap">
            <div className="logo">
              <img src={LogoMarkImg} alt="" />
            </div>
            <h2>{heading}</h2>

            <div className="form-block">
              <SearchEngineForm submitBtnText="LET’S TALK" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default FormSection
